import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import Hero from '../components/Screens/Hero';
import About from '../components/Screens/About';
import Games from '../components/Screens/Games';
// ====

const Index = (props) => {
  const { data } = props;

  let home;
  let about;
  let games;
  let contact;
  if (data) {
    home = JSON.parse(data.index.content);
    about = JSON.parse(data.about.content);
    games = JSON.parse(data.games.content);
    contact = JSON.parse(data.contact.content);
  }

  return (
    <Layout meta={home.meta}>
      <Hero title={home.title} copy={home.body} image={home.image} id="home" />
      <About
        title={about.title}
        copy={about.body}
        image={about.image}
        id="about"
      />
      <Games
        title={games.title}
        copy={games.body}
        image={games.image}
        items={games.games}
        id="games"
        layout="natural"
      />
      <Hero
        title={contact.title}
        copy={contact.body}
        image={contact.image}
        id="contact"
      />
    </Layout>
  );
};

export default Index;

// ====

export const QUERY = graphql`
  query HomeDefaultQuery {
    index: storyblokEntry(slug: { eq: "home" }, lang: { eq: "default" }) {
      content
    }
    about: storyblokEntry(slug: { eq: "about" }, lang: { eq: "default" }) {
      content
    }
    games: storyblokEntry(slug: { eq: "games" }, lang: { eq: "default" }) {
      content
    }
    contact: storyblokEntry(slug: { eq: "contact" }, lang: { eq: "default" }) {
      content
    }
  }
`;
