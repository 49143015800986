import React from 'react';
import PropTypes from 'prop-types';
import Richtext from '../Richtext/Richtext';
import ImageHandler from '../../utils/ImageHandler';

// ====

const About = (props) => {
  const { title, copy, image, id } = props;

  return (
    <article
      id={id}
      className="w-100 brand-screen brand-screen-full"
      style={{
        backgroundImage: `url(${ImageHandler(image.filename, '1920x1080')})`,
      }}
    >
      <div className="d-flex w-100 h-100 justify-content-center align-items-center">
        <div className="container pt-5 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-4 text-center text-white">
              {title && <h1 className="brand-font mb-3">{title}</h1>}
              {copy && <Richtext content={copy} />}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default About;

// ====

About.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  copy: PropTypes.shape,
  image: PropTypes.shape({
    filename: PropTypes.string,
    alt: PropTypes.string,
  }),
};

About.defaultProps = {
  id: null,
  title: null,
  copy: null,
  image: {
    filename: null,
    alt: null,
  },
};
